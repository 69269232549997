.hero {
    width: 100%;
    height: 100vh;
    color: #fff;
    position:relative;
    /* background-image: url('../../assets/FrenteAoMarTransp.jpg');
    background-position: center;
    background-size: cover;
    animation: moveRight 30s linear infinite; */
    /* background-image: url('../../assets/ImgMarau.jpg'); */
    /* background-color: #fff; */
}

.overlay {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    
}

.fade-in {
    animation: fadeIn 4s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hero .content {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 1rem;
}

.form {
    display: flex;
    background-color: #ffffffcc;
    color: #000;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 8px;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
}

.form input[type=text] {
    border: transparent;
    background-color: transparent;
    width: 400px;
    font-size: 1.2rem;
    font-family: 'Poppins',sans-serif;
}

.form input[type=text]:focus {
    outline: none;
}

@media screen and (max-width: 940px) {
    .form input[type=text] {
        max-width: 275px;
    }

}

 @media (max-width: 3460px) {
    .imagem {
        width: 20%;
     }
 }

 @media (max-width: 1866px) {
    .imagem {
        width: 20%;
     }
 }

 @media (max-width: 1024px) {
    .imagem {
        width: 30%;
     }
 }

 @media (max-width: 768px) {
    .imagem {
        width: 50%;
     }
 }

 @media (max-width: 480px) {
    .imagem {
        width: 60%;
     }
 }

.whats {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
}

