.popup-content {
    margin: auto;
    /* background: #fff; */
    /* background: #06df59; */
    background: transparent;
    width: 90%;
    height: auto;
    padding: 5px;
    /* border: 1px solid #d7d7d7; */
    
    /* border: 1px solid #1e016d; */
    /* padding: .7rem .7rem; */
    border-radius: 15px;
    color:#252525;
}

.popup-content img {
    
    border: 5px solid #1e016d;
    /* padding: .7rem .7rem; */
    border-radius: 15px;
    color:#252525;
}

.popup-content .carousel-status {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }

.cont-glambing {
    max-width: 900px;
    margin: 0 auto;
    /* display: flex; */
    display: inline-block;
    flex: 1 1 300px;
    align-content: center;
    /* flex-wrap: wrap; */
    
}

.divglamping {
    /* max-width: 300px; */
    color:#252525;
    /* display: none; */
    /* flex: 1 1 300px;
    align-content: center; */
    width: 100%;
    text-align: center;
    position: relative;
    /* background-image: url('../../assets/Glamping3_20.jpg'); */
    background-size:cover;
}

.divglamping img {
    max-width: 300px;
    /* display: inline-block;
    flex: 1 1 300px;
    align-content: center; */

}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Poppins", sans-serif;
}

:root {
    --primary-dark: #b6570a;
    --primary-light: #f58b12;
}

button {
    /* background-image: linear-gradient(45deg,var(--primary-dark) 0%, var(--primary-light) 100%);
    border: 1px solid var(--primary-dark); */
    color: #2e2d2d;
    padding: .7rem .7rem;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.8);
  }

.reactjs-popup-overlay.popup-overlay {
    background-color: transparent;
  }

@media screen and (max-width: 940px) {
    .popup-content {
        margin: auto;
        margin-left: -13px;
        background: transparent;
        width: 106%;
        height: auto;
        border: transparent;
        border-radius: 5px;
        padding: 1px;
        /* border: 1px solid #d7d7d7; */
    }

    button {
        /* background-image: linear-gradient(45deg,var(--primary-dark) 0%, var(--primary-light) 100%);
        border: 1px solid var(--primary-dark); */
        color: #2e2d2d;
        padding: .7rem .7rem;
        border-radius: 8px;
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
    }

    
    
}