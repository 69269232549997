@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@300;400;700&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap'); */

/* :root {
  --primary-dark: #5651e5;
  --primary-light: #709dff;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Poppins", sans-serif;
  /* overflow-x: hidden; */

}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
}
h2 {
  font-size: 2rem;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 0 1rem;
}

button {
  /* background-image: linear-gradient(45deg,var(--primary-dark) 0%, var(--primary-light) 100%); */
  background: transparent;
  
  border: 1px solid var(--primary-dark);
  color: #222121;
  padding: .7rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 940px) {
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

.img-fundo div {
  background-image: url('./assets/keywestOpaca.jpg');
  background-repeat:no-repeat;
  background-attachment: fixed;
  background-size:cover;
}
