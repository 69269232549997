.contato {
    width: 100%;
    text-align:left;
    position: relative;

    background-image: url('../../assets/PraiaTransp20.jpg');
    background-size:cover;
}

.contato h2 {
    padding: 1rem;
    text-align: center;
}

.img-container {
    --gap:1rem;
    --row-height:300px;
    margin: auto;
    padding: 2rem 0;
    gap: var(--gap);
}

.contato img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.span-3 {
    grid-column: span 3;
}

.image-grid-row-2 {
    grid-row: span 2;
}

.social-icons {
    /* display: flex; */
    justify-content:left;
    /* margin: 2rem 0; */
    /* text-align: justify; */
}

.social-icons .icon:hover {
    transform: scale(1.5);
    transition: 0.3s;
}

.icon {
    font-size: 2.5rem;
    padding-right: 15px;
}

@media screen and (max-width: 940px) {
    .img-container {
        /* --num-cols: 2; */
        --row-height: 200px;
        --grap: .5rem;
        
    }

    .span-3 {
        grid-column: span 2;
    }
}

@media screen and (max-width: 3460px) {
    .img-container {
        --num-cols: 2;
        --row-height: 200px;
        --grap: .5rem;
    }

    .span-3 {
        grid-column: span 2;
    }
}

.span {
    font-weight: bold;
}

div #canto-dir {
    margin: 0;
    justify-content:right;
}

div #canto-dir1 {
    margin: 0;
    justify-content:right;
    padding-right: 15px;
}

.cont-respon {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.cont-respon div {
    flex: 1 1 300px;
}
