.camping {
    width: 100%;
    text-align: center;
    position: relative;
}

.vid-container {
    --gap:1rem;
    --row-height:300px;
    padding: 5px;
    gap: var(--gap);
}

.camping img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.span-3 {
    grid-column: span 3;
}

.image-grid-row-2 {
    grid-row: span 2;
}

#video-camping {
    width: 300px;

}

.centro {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.imgcamping {
    max-width:330px;
    padding: 5px;
}

.cont-camping {
    max-width: 900px;
    padding: 15px;
}

.cont-camping div {
    position: relative;
} 

.padtop {
    padding-top: 30px;
    padding-bottom: 20px;
}

.esquerda {
    text-align: left;
    width: 80%;
    padding-top: 50px;
}

.folga {
    padding-top: 15px;
    padding-bottom: 15px;
}

.ajustado-baixo {
    padding-bottom: 20px;
    /* max-width: 980px; */
    margin: auto;
    padding: 0 1rem;
    padding-top: 20px;
    padding-bottom: 15px;
}

@media screen and (max-width: 940px) {
    .vid-container {
        --row-height: 200px;
        --grap: .5rem;
    }

    #video-camping {
        width: 80%;
    }

    .span-3 {
        grid-column: span 2;
    }

    .esquerda {
        text-align:justify;
        width: 100%;
        padding-top: 0;
    }
}

@media screen and (max-width: 3460px) {
    .vid-container {
        --num-cols: 2;
        --row-height: 200px;
        --grap: .5rem;  
    }

    .span-3 {
        grid-column: span 2;
    }
}

.span {
    font-weight: bold;
}
