.footer {
    width: 100%;
    padding: 1rem 0;
    background-color: #333;
    color: #fff;
}

.footer h3 {
    color: var(--primary-light);
    
}

.footer .top, .bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.footer .social {
    display: flex;
    justify-content: space-between;
    width: 280px;
}

.footer .icon {
   color: var(--primary-light);
}

.footer .left, .right {
    display: flex;
    align-items: center;
}

.corbtn {
    /* display: flex;
    align-items: center; */
    color: rgb(108, 181, 230);
    align-content: center;
}

.divcentro {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 0 ;
}

.footer ul {
    display: flex;
}

.footer li {
    padding: .5rem;
}

@media screen and (max-width: 1140px) {
    .footer ul {
        flex-direction: column;
    }
}