.experi {
    width: 100%;
    text-align: center;
    position: relative;

    /* background-image: url('../../assets/FrenteAoMarTransp.jpg'); */
    background-position: center;
    background-size: cover;
    background-color: #d0dad1;
    /* animation: moveRight 30s linear infinite; */
}




@keyframes moveRight {
    100% {
      background-position: 0% center;
    }
    0% {
      background-position: -100% center;
    }
  }

.experi-div {
    text-align: center;
    max-width: 960px;
    margin: auto;
}

.experi-div1 {
    text-align: center;
    padding: 30px;
    margin: auto;
}

.experi-carousel {
    text-align: center;
    /* width: 60%; */
    margin: auto;
}

.experi-carousel h2 {
    padding: 1rem;
}

.experi-carousel li {
    padding: 0 1rem;
}


.experi-carousel .slide {
    height: auto;
}

.experi-carousel .slide img {
    max-width: 100%;
    height: 100%;
}

.experi-carousel .carousel-status {
    position: absolute;
    right: 15px;
    bottom: 30px;
  }

.experi-image {
    --gap:1rem;
    /* --row-height:300px; */
    margin: auto;
    padding: 2rem 0;
    gap: var(--gap);
}

.conteudo {
    width: 100%;
    margin: 30px auto;
    max-width: 768px;
    padding: 10px 13px 30px 13px;
}

.conteudo h3 {
    padding-bottom: 25px;
}

.embed {
    
    width: 100%;
    margin: auto;
    position:relative;
    /* max-width: 768px; */
    padding-bottom: 56.25%; 
    /* padding-top: 30px; */
    
}

.tamanho iframe {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 7px
    /* padding-top: 20px; */

}

/* .embed .tamanho{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 0 0 0;
    padding-bottom: 56.25%; 
} */

/* .embed iframe {
    align-items: center;
    border-radius: 20px;
} */

.padtop {
    padding-top: 30px;
    padding-bottom: 20px;
}

.direita {
    text-align: right;
    width: 100%;
    padding-top: 50px;
    
}

.desloca {
    /* text-align: right;
    right: 5px; */
    right: 0;
    padding-left: 35px;
    padding-top: 30px;
    width: 100%;

}

@media screen and (max-width: 3460px) {
    /* .experi-carousel {
        width: 50%;
    } */
    .experi-imgage {
        --num-cols: 2;
        --row-height: 200px;
        --grap: .5rem;
    }

    .span-3 {
        grid-column: span 2;
    }
}

@media only screem and (max-width: 1200px) {
    .experi-carousel {
        width: 60%;
    }

    .experi-carousel .slide img {
        margin: 10vh auto;
    }
    .experi-image {
        max-height: 600px;
    }
}

@media screen and (max-width: 940px) {
    .experi-carousel {
        width: 95%;
    }
    .experi-imgage {
        --row-height: 200px;
        --grap: .5rem;
    }

    .span-3 {
        grid-column: span 2;
    }

    .direita {
        text-align: justify;
        width: 100%;
        padding-top: 0;
       
    }

    .desloca {
        right: 0;
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 15px;
        width: 100%;
    
    }
}

@media screen and (max-width: 390px) {
    .experi-carousel {
        width: 100%;
    }
    .experi-imgage {
        --row-height: 200px;
        --grap: .5rem;
    }

    .span-3 {
        grid-column: span 2;
    }
}

.span {
    font-weight: bold;
}

div #canto-dir1 {
    margin: 0;
    justify-content:right;
    padding-right: 15px;
}
