.glamping-carousel {
    width: 100%;
    text-align: center;
    position: relative;
    /* background-image: url('../../assets/Glamping3_20.jpg'); */
    background-position: center;
    /* background-size: cover; */
    animation: moveLeft 30s linear infinite;
    background-size:cover;
    background-color: #d5dbda;
}

.imgcarousel {
    max-width: 1200px;
}

.texto-glamping {
    padding: 20px;
}

.cont-glambing {
    max-width: 900px;
    margin: 0 auto;
    display: inline-block;
    flex: 1 1 300px;
    align-content: center;  
}

.divglamping button {
    border: 0;
}

.btnglamping {
    width: 300px;
    background: transparent;
    border-color: transparent;
    color: black;
    font-size: large;
}

.imglamping {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5%;
    padding: 5px;
}

.imglamping div {
    flex: 1 1 300px;
}

.my-popup-content {
    margin: auto;
    background: #fff;
    width: 80%;
    padding: 5px;
    border: 1px solid #d7d7d7;
}

.contentStyle { 
    width: '70%';
     height: 'auto' 
}

.padtop {
    padding-top: 30px;
}

.ajustado-baixo {
    padding-bottom: 20px;
    max-width: 980px;
    margin: auto;
    padding: 0 1rem;
    padding-top: 20px;
    padding-bottom: 15px;
}

@media screen and (max-width: 940px) {
    .popup-content {
        margin: auto;
        background: transparent;
        width: 100%;
        height: auto;
     }

    .my-popup-content li {
        padding: 0px;
    }

    .imglamping {
        max-width:100%;
    }

    .my-popup-content div {    
        background: transparent;
        padding: 0;
        
        /* padding: 5px; */
        /* border: 1px solid #d7d7d7; */
    }

    .popup-content img {
        /* border: 5px solid #1e016d; */
        padding: 0;
        border-radius: 5px;
        color: #252525;
    }

    .texto-glamping {
        padding: 0;
        padding-top: 20px;
        text-align: justify;
    }
    
}


  @media only screem and (max-width: 1200px) {
    .glamping-carousel {
        height: 90vh;
    }
    .glamping-carousel .slide img {
        
        margin: 10vh auto;
        max-width: 80%;
    }
    .image {
        max-height: 600px;
        width: auto;
    }

    .my-popup-content {
        margin: auto;
        width: 70%;
        height: auto;
        border-color: transparent;
        
        padding: 5px;
    }

    .imglamping {
        max-width:80%;
        border-radius: 5%;
        padding: 5px;
    }

    .carousel .slide {
        min-width: 80%;
        margin: 0;
        position: relative;
        text-align: center;
    }

    .my-popup-content div {    
        background: transparent;
        padding: 5px;
    }

}


@media screen and (max-width: 3460px) {
    .my-popup-content {
        margin: auto;
        background: transparent;
        max-width: 1200px;
        width: 80%;
        height: auto;
        border-color: transparent;
        
        padding: 50px; 
        border: 1px solid #d7d7d7;
    }

    .my-popup-content div {    
        background: transparent;
    }
}
