.locaquadri {
    align-content: center;
    background-color:beige;
}

.img-container {
    --gap:1rem;
    --row-height:300px;
    margin: auto;
    padding: 2rem 0;
    gap: var(--gap);
}

.locaquadri img {
    width: 90%;
    height: 90%;
    object-fit: cover;
}

.imgloca {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.imgloca div {
    flex: 1 1 300px;
}

.imgloca {
    max-width:360px;
    border-radius: 2%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.padtop {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: center;
}

.cont-quadri {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    
}

.cont-quadri div {
    flex: 1 1 300px;
    align-content: center;
}
