.sobre {
    width: 100%;
    /* height: 100vh; esta linha deixas a tela inteira*/
    text-align: center;
    position:relative;
    background-size:cover;
    background-origin: border-box;
    background-color: #f8e5e0;
}

@keyframes moveLeft {
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: -100% center;
    }
  }

.sobre h2 {
    padding: 1rem;
}

.imgsobre {
    max-width: 100%;    
}

.sobre .content {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 1rem;
}

.span-3 {
    grid-column: span 3;
}

.image-grid-row-2 {
    grid-row: span 2;
}

.ajustado {
    text-align: center;
    padding-top: 10px;
}

.ajustado-titulo {
    text-align: center;
    padding-top: 25px;
}

.ajustado-baixo {
    padding-bottom: 20px;
}

@media screen and (max-width: 940px) {
    .img-container {
        --row-height: 200px;
        --grap: .5rem;

    }

    .span-3 {
        grid-column: span 2;
    }

    .ajustado {
        text-align: justify;
        padding-bottom: 15px;
    }
}

@media screen and (max-width: 3460px) {
    .img-container {
        --num-cols: 2;
        --row-height: 200px;
        --grap: .5rem;

    }

    .span-3 {
        grid-column: span 2;
    }
}

.span {
    font-weight: bold;
}

div #canto-dir {
    margin: 0;
    justify-content:right;
}

#corfundo {
    background-color: darksalmon;
}
