.navbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    z-index: 2;
    position: absolute;
    /* position: fixed; deixa a navba sempre na parte superior*/
    color: #fff;
}

.navbar-bg {
    background-color: #f5f5f5;
}

.nav-menu {
    display: flex;
    align-content: center;
    cursor: pointer;
}

li {
    padding: 0 1rem;
}

.hamburger {
    display: none;
}

.mobile-menu {
    display: none;
    cursor: pointer;
}

.icon {
    font-size: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 940px) {
    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        top: -100vh;
        left: 0;
        position: absolute;
        justify-content: space-between;
        background-color: #f5f5f5;
        transition: 0.3s;
        color: #000;
    }

    .dark {
        color: #000;
    }

    .active {
        top: 80px;
    }

    .mobile-menu li {
        padding: 1.2rem 1rem;
        margin: 0 1rem;
        border-bottom: 1px solid #ccc;
    }

    .mobile-nav {
        margin: 1rem 0;
    }

    .mobile-menu-bottom {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
    }

    .social-icons {
        display: flex;
        justify-content: space-around;
        margin: 2rem 0;
    }

    .social-icons .icon:hover {
        transform: scale(1.5);
        transition: 0.3s;
    }

    .mobile-menu button {
        margin: 1rem;
        width: 90%;
    }


    .nav-menu {
        display: none;
    }

    .nav-icons {
        display: none;
    }
    .hamburger {
        display: block;
    }

}

@media (max-width: 3460px) {
    .h5text {
        font-size: 75%;
     }
 }

 @media (max-width: 1866px) {
    .h5text {
        font-size: 75%;
     }
 }

 @media (max-width: 1024px) {
    .h5text {
        font-size: 75%;
     }
 }

 @media (max-width: 768px) {
    .h5text {
        font-size: 75%;
     }
 }

 @media (max-width: 480px) {
    .h5text {
        font-size: 75%;
     }
 }
